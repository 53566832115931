import GLightbox from "glightbox";
import no from "vanillajs-datepicker/js/i18n/locales/no";
import * as basiclightbox from 'basiclightbox'

class Gallery {
    constructor(element){
        this.element = element;

        this.width = window.innerWidth;

        this.type = 'all';
        this.page = 1;
        if(this.width < 700){
            this.perPage = 1;
        } else if (this.width < 1000){
            this.perPage = 4;
        } else {
            this.perPage = 6;
        }

        this.lastPage = false;

        this.nextPage = this.element.querySelector('[data-element="next"]');
        this.prevPage = this.element.querySelector('[data-element="prev"]');
        this.buttons = this.element.querySelectorAll('[data-element="button"]');
        this.applicationContainer = this.element.querySelector('[data-element="container"]');

        this._nextPageEvent = this._nextPageEvent.bind(this);
        this._prevPageEvent = this._prevPageEvent.bind(this);
        this._changeTypeEvent = this._changeTypeEvent.bind(this);

        this.nextPage.addEventListener('click', this._nextPageEvent);
        this.prevPage.addEventListener('click', this._prevPageEvent);

        this.buttons.forEach(button => {
            button.addEventListener('click', this._changeTypeEvent);
        });

        this._loadApplications();

        window.addEventListener('resize', () => {
            if(this.width !== window.innerWidth) {
                this.width = window.innerWidth;
                if (this.width < 700) {
                    this.perPage = 1;
                } else if (this.width < 1000) {
                    this.perPage = 4;
                } else {
                    this.perPage = 6;
                }
                this.page = 1;
                this.lastPage = false;
                this._loadApplications();
            }
        });


    }

    _changeTypeEvent(event) {
        event.preventDefault();
        this.type = event.target.getAttribute('data-type');
        this.page = 1;
        this.lastPage = false;
        this.buttons.forEach(button => {
           let bt = button.getAttribute('data-type');
           if(bt == this.type) button.classList.add('active');
           else button.classList.remove('active')
        });
        this._loadApplications();
    }

    _nextPageEvent(event){
        if(!this.lastPage) {
            this.page++;
            this._loadApplications();
        }
    }

    _prevPageEvent(event){
        if(this.page > 1) {
            this.page--;
            this.lastPage = false;
            this._loadApplications();
        }
    }

    _getFromApi(){
        let query = this._buildQuery();
        let url = '/wp-json/wp/v2/application/?'+query;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if(!response.ok){
                return response.json().then(function(response){
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        })
    }

    _buildQuery(){
        let query = [
            'per_page='+this.perPage,
            'page='+this.page,
            '_embed'
        ];
        if(this.type !== 'all'){
            query.push('type='+this.type);
        }
        return query.join('&');
    }

    _loadApplications(){
        this.isLoading = true;
        this._getFromApi().then(rensponse => {
            if(rensponse.length > 0){
                this._generateApplications(rensponse);
                console.log(this.lastPage, this.page);
                if(this.lastPage){
                    this.nextPage.classList.add('dis-active');
                } else {
                    this.nextPage.classList.remove('dis-active');
                }
                if(this.page == 1){
                    this.prevPage.classList.add('dis-active');
                } else {
                    this.prevPage.classList.remove('dis-active');
                }
            } else {
                if(this.page == 1) {
                    this._showNoImages();
                }
            }
        }).catch(error => {
            if(error.message === 'rest_post_invalid_page_number'){
                this.page--;
                this.lastPage = true;
                this.nextPage.classList.add('dis-active');
            }
        });
    }

    _showNoImages() {
        this.applicationContainer.innerHTML = '';
        let noIm = document.createElement('div');
        noIm.classList.add('no-images');
        noIm.innerHTML = "Brak zdjęć!<br/>Czekamy na Twoje zgłoszenie!";


        this.applicationContainer.append(noIm);
    }

    _generateApplications(applications){
        this.applicationContainer.innerHTML = '';
        applications.forEach(application => {
            let applicationElement = this._generateApplication(application);
            this.applicationContainer.append(applicationElement);
            let lightbox = new GLightbox();
        });
        console.log(applications.length, this.perPage);
        if(applications.length < this.perPage){
            this.lastPage = true;
        }
        this.isLoading = false;
    }

    _generateApplication(application){
        let element = document.createElement('div');
        element.classList.add('slide__photo');

        let lightbox = null;

        if(typeof application._embedded !== "undefined" && application.content.rendered === '') {
            lightbox = document.createElement('a');
            lightbox.classList.add('glightbox');
            lightbox.setAttribute('data-gallery', application.id);
            lightbox.setAttribute('href', application._embedded["wp:featuredmedia"][0].source_url);
        } else {
            lightbox = document.createElement('a');
            lightbox.setAttribute('href', '');
            let modal = document.createElement('div');
            let modalContent = document.createElement('div');
            modalContent.classList.add('modal');
            if(typeof application._embedded !== "undefined"){
                let modalImage = document.createElement('img');
                modalImage.classList.add('modal__image');
                modalImage.setAttribute('src', application._embedded["wp:featuredmedia"][0].source_url);
                modalImage.setAttribute('alt', application.meta._first_name);
                modalContent.appendChild(modalImage);
            }
            let modalText = document.createElement('div');
            modalText.classList.add('modal__text');
            modalText.innerHTML = application.content.rendered;
            modalContent.appendChild(modalText);


            console.log(lightbox);

            let close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '&cross;';
            close.setAttribute('href', '');

            modalContent.appendChild(close);
            modal.appendChild(modalContent);

            let lbx = basiclightbox.create(modal);
            if(lbx.visible()){
                lbx.close();
            }
            lightbox.addEventListener('click', e => {
                e.preventDefault();
                lbx.show();
            })
            close.addEventListener('click', e => {
                e.preventDefault();
                lbx.close();
            })


        }


        let name = document.createElement('p');
        name.classList.add('slide__name');
        name.innerText = application.meta._first_name;


        let slideContainer = document.createElement('div');
        slideContainer.classList.add('slide__image-container');

        if(application.meta._award.length > 0) {
            let mask = document.createElement('div');
            mask.classList.add('slide__image-mask');
            let icons = document.createElement('div');
            icons.classList.add('slide__image-icons');
            application.meta._award.forEach(award => {
                let icon = document.createElement('img');
                icon.classList.add('slide__image-icon');
                icon.setAttribute('src', "/wp-content/themes/elmex/dist/images/icon-" + award + ".png");
                icons.appendChild(icon);
            });
            slideContainer.appendChild(mask);
            slideContainer.appendChild(icons);

        }

        if(typeof application._embedded !== "undefined") {
            let slideImage = document.createElement('img');
            slideImage.classList.add('slide__image');
            slideImage.setAttribute('src', application._embedded["wp:featuredmedia"][0].source_url);
            slideImage.setAttribute('alt', application.meta._first_name);

            slideContainer.appendChild(slideImage);
            if(application.content.rendered !== ''){
                slideContainer.classList.add('has-text');
                name.classList.add('slide__name-has-text');
                let showText = document.createElement('span');
                showText.classList.add('slide__name-show-text');
                showText.innerText = 'pokaż tekst';
                name.appendChild(showText);
                name.addEventListener('click', function() {
                    if(this.classList.contains('shown-text')){
                        this.parentNode.classList.remove('shown-text');
                        this.classList.remove('shown-text');
                        this.querySelector('span').innerText = 'pokaż tekst';
                    } else {
                        this.parentNode.classList.add('shown-text');
                        this.classList.add('shown-text');
                        this.querySelector('span').innerText = 'ukryj tekst';
                    }
                });
            }
        }

        if(application.content.rendered !== ''){
            let slideText = document.createElement('div');
            slideText.classList.add('slide__text');
            slideText.innerHTML = application.excerpt.rendered;

            slideContainer.appendChild(slideText);
        }

        lightbox.appendChild(slideContainer);

        element.appendChild(lightbox);
        element.appendChild(name);
        return element;
    }
}

export default Gallery;